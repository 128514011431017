import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DynamicImg from "../components/imageDynamic"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <h1>Impressum</h1>

    <div style={{ maxWidth: `200px`, marginBottom: `1.45rem` }}>
      <DynamicImg src="Holger_Strand.jpg" />
    </div>

    <p>
      Holger Off
      <br />
      Am Bronzehügel 106
      <br />
      22399 Hamburg
    </p>
    <p>
      Tel. 040 602 77 19
    </p>
  </Layout>
)

export default SecondPage
